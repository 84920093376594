<template>
	<div class="body_login">
		<router-view></router-view>
	</div>
</template>

<script>

export default {
	data() {
		return {}
	},

	mounted() {

	},

	beforeDestroy() {

	},

	methods: {}
}
</script>

<style lang="less" scoped>
.body_login {

}
</style>
